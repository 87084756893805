import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../Layout/Layout";
// import GraphQLErrorList from "../GraphQLErrorList/GraphQLErrorList";
import EventsDetail from "../Events/EventsDetail";
import EventsSliderSection from "../Events/EventSliderSection";
import EventsGallreySection from "../Events/EventsGallreySection";

export const query = graphql`
  query GetEventsPage($slug: String) {
    pagedata: sanityEventspage(slug: { current: { eq: $slug } }) {
      _id
      event_date_time_detail
      event_desc
      event_slider_section_title_highligted
      event_venue_detail
      id
      meta_desc
      meta_title
      title
      events_type
      slug {
        current
      }
      listing_event_image {
        asset {
          _id
        }
      }
      event_image {
        asset {
          _id
        }
      }
      event_short_description {
        children {
          text
        }
      }
      slides_to_show {
        event_date_time_detail
        event_desc
        event_slider_section_title_highligted
        event_venue_detail
        _rawEventShortDescription
        id
        title
        event_image {
          asset {
            id
          }
        }
        slug {
          current
        }
        listing_event_image {
          asset {
            _id
          }
        }
        event_short_description {
          children {
            text
          }
        }
      }
    }
    allSanityImageAsset {
      edges {
        node {
          _id
          url
        }
      }
    }
  }
`;

const EventsPage = ({ data, location }) => {
  // console.log(data,location,"events data")
  return (
    <>
      <Layout location={location}>
        <Helmet>
          <title>{data.meta_title || data.title}</title>
          <meta property="og:title" content={data.meta_title || data.title} />
          <meta
            property="twitter:title"
            content={data.meta_title || data.title}
          />
          <meta name="description" content={data.meta_desc} />
          <meta property="og:description" content={data.meta_desc} />
          <meta property="twitter:description" content={data.meta_desc} />{" "}
          <meta
            name="theme-color"
            content="#144a95"
            media="(prefers-color-scheme: light)"
          />
          <meta
            name="theme-color"
            content="#144a95"
            media="(prefers-color-scheme: dark)"
          />
          <meta name="theme-color" content="#144a95" />
        </Helmet>
        <div className="z-10" location={location}>
          <EventsDetail data={data} location={location} />
          <EventsGallreySection data={data} location={location}/>
          <EventsSliderSection data={data} location={location} />
        </div>
      </Layout>
    </>
  );
};

export default EventsPage;
