import { Link } from "gatsby";
import React from "react";
import GatsbyImageCustom from "../Image/GatsbyImageCustom";

const EventSliderComponent = (props) => {
    // console.log(props,"sad")
    
  return (
    <div className="p-2 py-10 w-full lg:w-64 mx-auto z-10">
      <div className=" ">
        <Link to={`/events/${props.data.slug.current}`}>
          <div className="shadow-lg hover:shadow-1xl">
            <div className="">
              <GatsbyImageCustom
                id={props.data.listing_event_image.asset._id}
                className="w-full"
                alt="img"
              />
            </div>
            <div className="h-40">
              <div className="border bg-gray-100 p-4 shadow-lg min-h-full">
                <div className="">
                  <div className=" text-base font-bold text-center pb-2 min-h-full">
                    {props.data.title}
                  </div>
                </div>
                {/* <div className="text-base font-medium italic pb-5">
                        { props.data._rawEventShortDescription[0].children[0].text}
                        </div> */}
                {/* <div className=" text-xs font-medium pb-3 flex h-10">
                      <div className="pr-2 w-36 max-w-full">
                        {moment(props.data.event_date_time_detail).format(
                          "dddd DD MMMM"
                        )}
                      </div>

                      <span className="pl-2 border-l-2 border-secondary-darkblue-500">
                        {moment(props.data.event_date_time_detail).format(
                          "HH:mm A"
                        )}
                      </span>
                    </div> */}
                <div className=" text-sm font-medium text-center pb-4">
                  {props.data.event_venue_detail}
                </div>
                <div className=" text-sm font-bold text-center uppercase">
                  {props.data._rawEventShortDescription[1].children[0].text}
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default EventSliderComponent;
