import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
// import GatsbyImageCustom from "../Image/GatsbyImageCustom";

const EventsGallreySection = (props) => {
    // console.log(props,"sad")
  return (
    <>
      <div className="max-w-6xl mx-auto z-10">
        <div className="lg:px-16 px-6 pt-20">
          <div className="">
            <h1 className=" md:text-4xl text-2xl text-center text-secondary-darkpurple-500 font-bold">
              {/* {props.data.pagedata.event_slider_section_title_highligted} */}
              Photo Gallery
            </h1>
          </div>
          <div className="py-20 lg:px-16 px-6 ">
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2 ">
              {props.data.pagedata.event_gallery_section &&
                props.data.pagedata.event_gallery_section.map((aa, index) => {
                    // console.log(aa,"dd")
                  let ImageData = props.data.allSanityImageAsset.edges.find(
                    (xx) => xx.node._id === aa.asset._id
                  );
                //   console.log("aaa", ImageData, aa);

                  return (
                    <div className="relative" key={index}>
                      <div className="featured-imagebox featured-imagebox-portfolio">
                        <div className="featured-thumbnail">
                          <a href="">
                            <img
                              className="img-fluid h-80 w-full object-cover"
                              src={ImageData.node.url}
                              alt="image"
                            />
                          </a>
                        </div>

                        <div className="ttm-box-view-overlay">
                          <div className="ttm-media-link">
                            <a
                              className="ttm_prettyphoto ttm_image"
                              data-gal="prettyPhoto[gallery1]"
                            //   title={data.pagedata.title}
                              href={ImageData.node.url}
                              data-rel="prettyPhoto"
                            >
                              <i className="ti ti-search">
                                <FontAwesomeIcon icon={faSearch} size="1x" />
                              </i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventsGallreySection;
