// import { Link } from "gatsby";
// import moment from "moment";
import React from "react";
import Slider from "react-slick";
// import IMG1 from "../../assets/images/event1.png";
// import IMG2 from "../../assets/images/event2.png";
// import IMG3 from "../../assets/images/event3.png";
// import GatsbyImageCustom from "../Image/GatsbyImageCustom";
import EventSliderComponent from "./EventSliderComponent";

const EventSliderSection = (props) => {
  // console.log(props, "dfg");
  const settings = {
    // className: "center",
    centerMode: false,
    infinite: true,
    centerPadding: "90px",
    slidesToShow: 4,
    arrows: false,
    // speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          // infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 360, 
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="bg-gray-200 z-10">
        <div className=" px-6 pt-10 mx-auto max-w-6xl relative ">
          <div>
            <h1 className=" md:text-4xl text-2xl text-center text-secondary-darkpurple-500 font-bold">
              {/* {props.data.pagedata.event_slider_section_title_highligted} */}
              Similar Events
            </h1>
          </div>

          <div className="lg:py-10 p-0 gap-2">
            <Slider {...settings}>
              {props.data.pagedata.slides_to_show
                .filter((aa) => aa.title !== props.data.pagedata.title)
                .map((aa, idx) => {
                  // console.log(aa, "sad");
                  return <EventSliderComponent data={aa} key={idx} />;
                })}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventSliderSection;
