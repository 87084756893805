// import { Link } from "gatsby";
// import moment from "moment";
import React from "react";
import { FacebookIcon, FacebookShareButton } from "react-share";
import Bg_img from "../../assets/images/AmyToken_bg.jpg";
import GatsbyImageCustom from "../Image/GatsbyImageCustom";

const EventsDetail = (props) => {
  // console.log(props, "sad");
  return (
    <div className="relative z-10">
      <div className="relative  w-full">
        <img
          src={Bg_img}
          alt="events"
          className="w-full object-cover h-auto lg:h-500"
          // style={{ height: "500px" }}
        />
      </div>
      <div className="relative z-20 -mt-48 lg:-mt-96 max-w-6xl mx-auto pt-10 px-4">
        <div className="flex flex-col lg:flex-row">
          <div className="bg-gray-100 h-auto lg:w-750 w-full">
            <GatsbyImageCustom
              id={props.data.pagedata.event_image.asset._id}
              className="h-full w-full"
            />
          </div>
          <div className=" bg-gray-100 lg:w-402 w-full h-auto">
            <div className=" border-b p-4 lg:p-8">
              <div style={{ height: 278, minHeight: "100%" }}>
                {/* <div className=" font-normal text-xl xl:pb-7 pb-6">
                {moment(props.data.pagedata.event_date_time_detail).format(
                  "DD, MMMM"
                )}
              </div> */}
                <div className=" font-normal text-lg pb-3 text-secondary-darkblue-500">
                  {props.data.pagedata.title}
                </div>
                {/* <div className=" font-normal text-sm pb-10">
                {
                  props.data.pagedata.event_short_description[0].children[0]
                    .text
                }
              </div> */}
                <div className=" font-normal text-sm pb-3">
                  {
                    props.data.pagedata.event_short_description[1].children[0]
                      .text
                  }
                </div>
                <div className=" font-bold text-base pb-3">Venue</div>
                <div className=" font-normal text-sm pb-6">
                  {props.data.pagedata.event_venue_detail}
                </div>
              </div>
              <div>
                {props.data.pagedata.events_type === true ? (
                  <div className="font-bold xl:text-sm lg:text-xs ">
                    <a href="tel:+919320793207">
                      <button className="custom-btn">Book Now</button>
                    </a>
                  </div>
                ) : (
                  " "
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex lg:flex-row flex-col pt-10 lg:pt-0">
          <div className="p-6 lg:p-12 bg-gray-100 w-full">
            {/* <div className=" font-bold text-lg pb-4">Description</div> */}
            <div className=" font-normal text-base pb-12">
              {props.data.pagedata.event_desc}
              <br />
            </div>
            <div className=" font-bold text-lg pb-6">Share</div>
            <div className="flex space-x-4">
              <FacebookShareButton url={props.location.href}>
                <FacebookIcon size={32} round={true} />
              </FacebookShareButton>
              {/* <InstapaperShareButton url={props.location.href}>
                <FontAwesomeIcon icon={faInstagram} className="bg-red-500 rounded-full text-white w-8 h-8"/>
              </InstapaperShareButton> */}
              {/* <FacebookShareButton url={"https://www.facebook.com/amethhyyst/"} quote="sad" hashtag="#programing joke" >
                <FacebookIcon />
              </FacebookShareButton> */}
            </div>
          </div>

          {/* <div className="p-6 lg:p-12 bg-gray-100 lg:w-402 w-full"> */}
          {/* <div className=" font-bold text-lg pb-4">Date & Time</div>
            <div className=" font-normal text-base pb-4">
              {moment(props.data.pagedata.event_date_time_detail).format(
                "dddd, DD MMMM"
              )}
            </div>
            <div className=" font-normal text-base pb-16">
              {moment(props.data.pagedata.event_date_time_detail).format(
                "HH:mm A"
              )}
            </div> */}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default EventsDetail;
